.b-main-news {
    margin-top: 30px;

    &__link {
        color: $c-color-foreground;
    }

    &__link:hover {
        text-decoration: none;
        color: $c-color-primary;
    }

    &__title {
        margin-top: 35px;
        font-family: $c-font-family-heading;
        font-size: 1.2857142857rem;
        font-weight: 700;
    }

    &__image {
        display: block;
        width: 285px;
        float: left;
    }

    &__details {
        width: 285px;
        float: left;
        margin-left: 30px;

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        .html_version_vi & {
            width: 100%;
            margin-left: 0;
        }
    }

    &__announce {
        margin: 0 0 1rem 0;
    }

    &__date {
        display: block;
        margin: 0;
        text-align: right;
        font-size: 0.8571428571rem;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }

}