.b-banners {
    margin-top: 50px;

    &__list {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__list-item {
        display: block;
        margin-bottom: 0;
    }

    &__link {
        display: block;

        img {
            width: 100%;
        }
    }

    .html_version_vi & {
        display: none;
    }

}