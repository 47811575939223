.b-header {
    width: 100%;
    height: 190px;
    background: $c-color-tertiary;

    .html_version_vi & {
        background: $c-color-foreground;
    }

    &__wrapper {
        position: relative;
        width: $c-layout-main-width;
        height: 100%;
        margin: 0 auto;
        background: url('#{$c-path-images}/header-image.png') 60px 0 no-repeat;

        .html_version_vi & {
            background: none;
        }
    }


    &__logo-img {
        position: absolute;
        top: 20px;
        right: 0;
        width: 100px;
        height: 39px;
    }

    &__title {
        position: absolute;
        top: 57px;
        left: 120px;
        width: 600px;
        font-family: $c-font-family-heading;
        font-size: 1.8571428571rem;
        font-weight: 700;
        line-height: 144%;
        text-align: right;
        text-transform: uppercase;
        color: $c-color-background;

        .html_version_vi & {
            left: 0;
            text-align: left;
        }
    }
}