.b-form {
    width: 100%;

    input[type="text"],
    input[type="email"],
    textarea {
        width: 100%;
    }

    input[type="submit"] {
        padding: 10px 20px;
        font-family: $c-font-family-heading;
        font-size: 1.2857142857rem;
        font-weight: 500;
        color: $c-color-foreground;
        text-align: center;
        background-color: $c-color-secondary;
        border: none;

        &:hover {
            background-color: $c-color-primary;
            color: $c-color-background;
            box-shadow: 0 2px 10px rgba(0,0,0,0.3);
            cursor: pointer;
        }
    }

    ul.errorlist {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            color: $c-color-primary;
        }
    }
}