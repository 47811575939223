.b-organization-list {

    &__title {
        font-family: $c-font-family-heading;
        font-weight: 700;
        font-size: 1.7142857143rem;
    }

    &__filter_district,
    &__filter_type {
        font-family: $c-font-family-heading;
        font-weight: 700;
        font-size: 1.1428571429rem;
        color: lighten($c-color-foreground, 50%);
    }

    &__table {
        margin-top: 3rem;
    }

    &__org-name {
        width: 40%;
    }

    td {
        padding: 10px;
    }

}