.b-main-banner {
    width: $c-layout-content-width;

    &__slider {
        img {
            width: 100%;
        }
    }

    .tns-nav {
        text-align: center;

        button {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            padding: 0;
            margin: 1rem 0.25rem;
            border: none;
            border-radius: 1rem;
            background-color: $c-color-tertiary;

            &.tns-nav-active {
                background-color: $c-color-primary;
            }
        }
    }

    .html_version_vi & {
        display: none;
    }

}