.b-material {

    &__title {
        font-family: $c-font-family-heading;
        font-size: 1.7142857143rem;
        font-weight: 700;
    }

    &__files-title {
        margin-top: 2rem;
        font-family: $c-font-family-heading;
        font-weight: 700;
        font-size: 1.1428571429rem;
    }

    &__files-item {
        font-size: 1.1428571429rem;
    }

}