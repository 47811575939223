.b-top-nav {
    margin: 0;
    background-color: $c-color-secondary;

    &__list {
        display: table;
        width: $c-layout-main-width;
        margin: 0 auto;
        padding: 0;
        list-style: none;
    }

    &__list-item {
        display: table-cell;
        margin: 0;
        padding: 10px 0;

        &_right {
            text-align: right;
        }
    }

    &__link {
        display: inline-block;
        font-family: $c-font-family-body;
        font-size: 1rem;
        font-weight: 400;
        color: $c-color-foreground;

        &_normal {
            display: none;

            .html_version_vi & {
                display: inline-block;
            }
        }

        &_vi {
            .html_version_vi & {
                display: none;
            }
        }
    }

    &__link:hover {
        text-decoration: none;
        color: $c-color-primary;
    }

    &__text {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.5rem;
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        width: 1.5rem;
        height: 1.5rem;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__icon-vi {
        background-image: url('#{$c-path-images}/vi-icon-64.png');
    }

    &__icon-search {
        background-image: url('#{$c-path-images}/search-icon-64.png');
    }

}