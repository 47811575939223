.b-news-list {
    margin-top: 20px;

    &__item {
        display: block;
        width: 285px;
        float: left;
        margin-right: 30px;
    }

    &__link {
        color: $c-color-foreground;
    }

    &__link:hover {
        text-decoration: none;
        color: $c-color-primary;
    }

    &__title {
        margin-top: 35px;
        font-family: $c-font-family-heading;
        font-size: 1.2857142857rem;
        font-weight: 700;
    }

    &__announce {
        margin: 1rem 0 1rem 0;
    }

    &__image {
        width: 100%;
    }

    &__details {

    }

    &__date {
        display: block;
        margin: 0;
        text-align: right;
        font-size: 0.8571428571rem;
    }
}