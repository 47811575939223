.b-left-menu {
    border-right: 2px solid $c-color-secondary;

    &__list {
        display: block;
        margin: 0;
        padding: 0 20px 0 0;
        list-style: none;
    }

    &__list-item {
        display: block;
        margin-bottom: 1rem;
    }

    &__link {
        font-family: $c-font-family-heading;
        font-size: 1.2857142857rem;
        font-weight: 500;
        color: $c-color-foreground;
    }

    &__link:hover {
        text-decoration: none;
        color: $c-color-primary;
    }

}