.b-material-list {
    margin-top: 20px;

    &__item {
        display: inline-block;
        width: 100%;
    }

    &__link {
        color: $c-color-foreground;
    }

    &__link:hover {
        text-decoration: none;
        color: $c-color-primary;
    }

    &__title {
        font-family: $c-font-family-heading;
        font-size: 1.2857142857rem;
        font-weight: 700;
    }

}