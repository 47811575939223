.b-main {
    display: table;
    width: $c-layout-main-width;
    margin: 0 auto;

    &__left-col {
        display: table-cell;
        vertical-align: top;
        width: $c-layout-col-width;
    }

    &__content {
        display: table-cell;
        vertical-align: top;
        padding: 0 50px;
    }

    &__right-col {
        display: table-cell;
        vertical-align: top;
        width: $c-layout-col-width;
    }
}