.b-org-menu {
    $b: &;
    margin-top: 50px;

    &__title {
        font-size: 1.2857142857rem;
        font-weight: 700;
    }

    &__list {
        display: block;
        margin: 30px 0 0 0;
        padding: 0;
        list-style: none;
    }

    &__list-item {
        display: block;
        margin-bottom: 2rem;

        .html_version_vi & {
            img {
                display: inline;
            }
        }
    }

    &__link {
        display: block;
        padding: 40px 20px;
        font-family: $c-font-family-heading;
        font-size: 1.2857142857rem;
        font-weight: 500;
        color: $c-color-foreground;
        text-align: center;
        background-color: $c-color-secondary;
    }

    &__link:hover {
        text-decoration: none;
        color: $c-color-background;
        background-color: $c-color-primary;
        box-shadow: 0 2px 10px rgba(0,0,0,0.3);

        #{$b}__icon {
            filter: brightness(10);
        }
    }

    &__icon {
        filter: brightness(0);
    }

}