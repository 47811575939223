.b-organization-types {

    &__item {
        display: inline-block;
        width: 100%;
    }

    &__link {
        color: $c-color-foreground;
    }

    &__link:hover {
        text-decoration: none;
        color: $c-color-primary;
    }

    &__title {
        font-family: $c-font-family-heading;
        font-weight: 700;

        &_h1 {
            font-size: 1.7142857143rem;
        }

        &_h2 {
            font-size: 1.2857142857rem;
        }

        &_h3 {
            font-size: 1rem;
        }
    }

}