.b-right-menu {

    &__list {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__list-item {
        display: block;
        margin-bottom: 2rem;
    }

    &__link {
        display: block;
        padding: 40px 30px;
        color: $c-color-foreground;
        text-align: center;
        background-color: $c-color-secondary;
    }

    &__link:hover {
        text-decoration: none;
        color: $c-color-background;
        background-color: $c-color-primary;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }

}