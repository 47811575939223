.b-feedback {

    &__title {
        font-family: $c-font-family-heading;
        font-size: 1.7142857143rem;
        font-weight: 700;
    }

    textarea {
        height: 300px;
    }

}