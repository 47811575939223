.b-main-menu {
    width: $c-layout-main-width;
    margin: 40px auto;

    &__list {
        display: block;
        float: right;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__list-item {
        display: block;
        float: left;
        //height: $c-font-size * 1.7142857143;
        margin-left: 20px;
        padding: 5px;

        &_active {
            border-bottom: 3px solid $c-color-primary;
        }
    }

    &__link {
        font-family: $c-font-family-heading;
        font-size: 1.2857142857rem;
        font-weight: 500;
        color: $c-color-foreground;
    }

    &__link:hover {
        text-decoration: none;
        color: $c-color-primary;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}