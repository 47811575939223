.b-search {
    &__switch {
        margin: 0 0 1rem 0;
        padding: 0;

        &-item {
            margin-right: 1rem;
            display: inline;
        }

        &-link {
            &_active {
                font-weight: bold;
                color: $c-color-foreground;
            }
        }
    }

    &__tags {
        margin-top: 2rem;
        display: none;
    }
}