.b-messages {

    ul {
        list-style: none;
        margin: 30px;
        padding: 0;
    }

    li {
        display: block;

        &.success {
            font-size: 1.2857142857rem;
            color: $c-color-tertiary;
        }
    }

}