.b-tags {
    $b: &;

    display: block;
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    &__item {
        display: block;
        float: left;
        margin-right: 10px;
        margin-bottom: 5px;
    }

    &__link {
        display: block;
        padding: 5px 15px;
        color: $c-color-foreground;
        background-color: $c-color-secondary;
        font-size: 0.8571428571rem;
        border-radius: 0.8571428571rem;
    }

    &__link:hover {
        text-decoration: none;
        color: $c-color-background;
        background-color: $c-color-primary;
        box-shadow: 0 2px 10px rgba(0,0,0,0.3);
    }
}